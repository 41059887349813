import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 780.000000 320.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,320.000000) scale(0.100000,-0.100000)">
					<path d="M2190 2301 c0 -5 15 -12 33 -15 18 -3 66 -22 106 -41 138 -66 247
-211 271 -360 5 -32 15 -63 21 -69 20 -20 -1 94 -31 167 -62 148 -177 256
-329 308 -61 21 -71 23 -71 10z"/>
					<path d="M2180 2192 c0 -5 19 -15 43 -22 130 -38 253 -174 275 -302 4 -23 12
-43 19 -45 16 -6 -2 68 -32 135 -34 75 -132 174 -203 205 -74 32 -102 40 -102
29z"/>
					<path d="M2170 2070 c0 -5 6 -10 13 -10 21 0 90 -40 125 -74 35 -32 82 -114
82 -141 0 -8 7 -15 16 -15 11 0 14 6 9 23 -11 35 -51 99 -86 136 -60 62 -159
113 -159 81z"/>
					<path d="M1413 2026 c-90 -29 -157 -90 -199 -180 -24 -50 -24 -55 -24 -396 l0
-345 29 -60 c35 -74 102 -136 178 -165 53 -20 73 -21 392 -18 l336 3 68 33
c77 38 124 92 157 179 20 53 21 72 18 387 l-3 331 -27 57 c-31 66 -90 127
-156 159 -46 24 -52 24 -387 26 -260 2 -350 -1 -382 -11z m754 -144 c26 -16
19 -70 -11 -83 -47 -22 -89 28 -59 70 14 21 48 27 70 13z m-409 -344 c-3 -247
-4 -265 -25 -303 -66 -122 -211 -167 -345 -107 -43 19 -88 56 -88 71 0 4 16
29 37 55 36 46 36 46 57 28 35 -32 62 -42 114 -42 38 0 53 5 69 23 41 44 43
59 43 301 l0 236 71 0 70 0 -3 -262z m172 72 c0 -232 3 -235 105 -105 l67 84
44 -35 c24 -20 44 -40 44 -45 0 -4 -25 -37 -55 -73 -30 -35 -55 -67 -55 -71 0
-3 40 -59 90 -124 100 -133 100 -129 -10 -127 l-55 2 -47 64 c-61 84 -102 130
-116 130 -7 0 -12 -35 -14 -97 l-3 -97 -62 -2 -63 -1 0 343 0 344 65 0 65 0 0
-190z"/>
					<path d="M3905 1633 c-3 -27 -8 -60 -10 -75 -4 -22 -1 -28 14 -28 15 0 22 12
31 52 6 29 13 63 16 75 5 19 2 23 -19 23 -23 0 -26 -5 -32 -47z"/>
					<path d="M2830 1500 l0 -170 -29 -32 c-25 -28 -36 -33 -74 -33 -31 0 -55 7
-75 22 -29 22 -52 16 -52 -12 0 -21 76 -55 124 -55 57 0 97 19 128 62 22 31
23 43 26 211 l4 177 -26 0 -26 0 0 -170z"/>
					<path d="M4410 1445 l0 -225 40 0 40 0 0 95 c0 94 0 95 24 95 18 0 45 -24 106
-95 l82 -95 56 0 56 0 -30 33 c-17 17 -63 69 -103 115 -65 73 -72 84 -60 100
8 9 43 51 78 92 36 41 72 83 80 93 13 16 10 17 -40 16 l-54 0 -70 -89 c-57
-72 -75 -89 -97 -89 l-28 -1 0 90 0 90 -40 0 -40 0 0 -225z"/>
					<path d="M6410 1575 c0 -43 -1 -45 -29 -45 -16 0 -31 -6 -34 -12 -3 -10 -13
-9 -41 5 -92 43 -205 -5 -226 -95 -6 -28 -8 -30 -14 -13 -28 78 -64 114 -126
122 -53 7 -84 -2 -124 -38 -76 -66 -65 -204 21 -260 40 -27 137 -26 182 2 l33
20 -21 23 c-21 23 -23 23 -67 7 -40 -14 -47 -14 -71 -1 -27 16 -51 45 -42 54
2 3 54 6 114 7 108 2 109 1 116 -23 18 -69 74 -108 156 -108 46 0 113 28 113
48 0 5 -10 18 -22 30 -21 18 -26 19 -48 7 -42 -24 -75 -18 -105 17 -62 73 12
176 96 132 27 -13 34 -14 45 -2 9 8 29 12 54 10 l40 -4 0 -90 c0 -86 1 -91 29
-119 24 -23 38 -29 74 -29 41 0 107 16 107 26 0 3 -7 18 -15 35 -13 24 -19 28
-35 19 -57 -30 -90 5 -90 97 l0 63 60 0 60 0 0 35 0 35 -60 0 -60 0 0 45 c0
45 0 45 -35 45 -35 0 -35 0 -35 -45z m-448 -122 c34 -41 32 -43 -41 -43 -75 0
-85 8 -54 42 21 23 75 24 95 1z"/>
					<path d="M4865 1526 c-56 -25 -88 -70 -93 -129 -8 -105 47 -169 153 -175 50
-3 65 0 94 20 58 39 76 72 76 138 0 69 -20 103 -81 137 -44 26 -104 29 -149 9z
m133 -92 c31 -36 29 -79 -7 -115 -58 -57 -141 -23 -141 59 0 82 93 117 148 56z"/>
					<path d="M5226 1519 c-21 -17 -26 -18 -26 -5 0 12 -10 16 -40 16 l-40 0 0
-155 0 -155 40 0 40 0 0 103 c0 109 5 123 53 140 19 7 56 -8 69 -27 4 -6 8
-57 8 -113 l0 -103 40 0 40 0 0 115 c0 84 -4 124 -15 145 -30 58 -119 79 -169
39z"/>
					<path d="M5563 1520 c-27 -16 -33 -17 -33 -5 0 10 -11 15 -35 15 l-35 0 0
-155 0 -155 35 0 35 0 0 89 c0 49 4 101 10 116 13 34 53 49 88 33 26 -13 27
-16 32 -123 l5 -110 38 -3 37 -3 0 123 c0 135 -7 155 -60 183 -39 20 -79 19
-117 -5z"/>
					<path d="M3010 1512 c-52 -26 -80 -74 -80 -137 0 -90 65 -155 153 -155 87 0
148 57 154 144 6 68 -19 114 -77 144 -53 26 -103 28 -150 4z m130 -37 c38 -20
52 -46 52 -100 0 -54 -14 -80 -52 -100 -50 -26 -97 -20 -136 19 -27 27 -34 42
-34 73 0 95 88 151 170 108z"/>
					<path d="M3323 1519 c-37 -11 -46 -21 -37 -42 5 -13 10 -14 39 -1 55 22 93 18
126 -15 48 -48 40 -61 -36 -61 -36 0 -81 -6 -100 -14 -62 -26 -74 -89 -26
-137 24 -23 38 -29 73 -29 24 1 54 7 68 15 33 19 50 19 50 -1 0 -9 7 -14 18
-12 15 3 17 17 20 105 3 125 -10 167 -62 188 -39 17 -85 18 -133 4z m157 -181
c0 -44 -46 -78 -105 -78 -48 0 -65 14 -65 52 0 37 22 47 103 48 63 0 67 -1 67
-22z"/>
					<path d="M3674 1520 c-12 -5 -25 -13 -29 -19 -9 -16 -25 -13 -25 4 0 8 -9 15
-20 15 -19 0 -20 -7 -20 -150 0 -143 1 -150 20 -150 19 0 20 7 20 95 0 107 14
150 56 165 44 17 74 11 105 -19 28 -29 29 -32 29 -135 0 -99 1 -106 20 -106
19 0 20 7 20 110 0 81 -4 119 -16 141 -24 48 -103 72 -160 49z"/>
					<path d="M4015 1517 c-29 -14 -55 -52 -55 -82 0 -34 44 -65 110 -76 36 -7 71
-16 78 -21 19 -16 14 -56 -7 -68 -28 -15 -111 -12 -143 4 -25 13 -29 13 -38
-4 -8 -16 -4 -21 35 -35 31 -12 62 -16 101 -13 49 4 62 10 86 37 33 37 35 59
8 94 -15 19 -36 29 -78 37 -31 6 -67 15 -79 20 -30 13 -31 55 -2 71 21 11 90
8 135 -6 15 -5 23 -2 27 10 13 33 -122 57 -178 32z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
